import { CircularProgress } from '@material-ui/core'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import React, { useCallback, useEffect, useState } from 'react'
import { getRecipientStatus } from 'services/api'
import { Colors } from 'styles'
import * as Styled from './Recipient.styled'
import { Container } from './Recipient.styled'
import RecipientForm from 'components/RecipientForm/RecipientForm'
import RecipientKyc from 'components/RecipientKyc/RecipientKyc'
import RecipientStatusInfo, {
  isDisplayableStatus,
} from 'components/RecipientStatusInfo/RecipientStatusInfo'

type RecipientStatus =
  | 'pending'
  | 'registration'
  | 'affiliation'
  | 'refused'
  | 'suspended'
  | 'active'
  | 'blocked'
  | 'inactive'

interface IError {
  message?: string
  tryAgainFunctionName?: string
}

interface RecipientProps {
  podcastId: string
}

const Recipient: React.FC<RecipientProps> = ({ podcastId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [recipientStatus, setRecipientStatus] =
    useState<RecipientStatus | null>(null)
  const [error, setError] = useState<IError>({})

  const fetchRecipientStatus = useCallback(async () => {
    setIsLoading(true)
    const statusOrError = await getRecipientStatus(podcastId)

    if (statusOrError.hasFailed()) {
      setIsLoading(false)

      if (statusOrError.error.message === 'Recipient not found') {
        setRecipientStatus('pending')
        return
      }

      setError({
        message: 'Falha ao buscar os dados.',
        tryAgainFunctionName: 'fetchRecipientStatus',
      })
      return
    }

    setIsLoading(false)
    setRecipientStatus(statusOrError.data.status)
  }, [podcastId])

  const handleCreatedRecipient = useCallback(async () => {
    setIsLoading(true)

    let status: RecipientStatus = 'registration'
    let tries = 0
    do {
      tries++
      await new Promise((resolve) => setTimeout(resolve, 5000))
      const statusOrError = await getRecipientStatus(podcastId)

      status = statusOrError.data?.status || 'registration'
    } while (['registration', 'pending'].includes(status) && tries < 6)

    setRecipientStatus(status)
    setIsLoading(false)
  }, [podcastId])

  useEffect(() => {
    fetchRecipientStatus()
  }, [fetchRecipientStatus])

  return (
    <Container>
      <Styled.InfoContainer>
        <Styled.InfoText>
          Agora, vai ser ainda mais simples receber seus pagamentos via Orelo 🎉
          <br />
          Nos próximos dias, você poderá completar o seu registro nesta mesma
          página!
        </Styled.InfoText>
      </Styled.InfoContainer>

      {/*
      {isLoading ? (
        <Styled.InfoContainer>
          <CircularProgress style={{ color: Colors.PURPLE[400] }} />
          <Styled.InfoText>Carregando dados...</Styled.InfoText>
        </Styled.InfoContainer>
      ) : error.message ? (
        <Styled.InfoContainer>
          <Styled.InfoText>{error.message}</Styled.InfoText>
          <ButtonShadow
            label="Tentar novamente"
            variant="secondary"
            onPress={
              error.tryAgainFunctionName === 'fetchRecipientStatus'
                ? fetchRecipientStatus
                : undefined
            }
          />
        </Styled.InfoContainer>
      ) : recipientStatus === 'pending' ? (
        <RecipientForm
          podcastId={podcastId}
          handleCreatedRecipient={handleCreatedRecipient}
        />
      ) : recipientStatus === 'affiliation' ? (
        <RecipientKyc
          podcastId={podcastId}
          setRecipientStatus={setRecipientStatus}
        />
      ) : isDisplayableStatus(recipientStatus) ? (
        <RecipientStatusInfo recipientStatus={recipientStatus} />
      ) : (
        <Styled.InfoContainer>
          <Styled.InfoText>
            Processo enviado. Você receberá os próximos passos para finalizar o
            cadastro em seu email. Você já pode sair desta página.
          </Styled.InfoText>
        </Styled.InfoContainer>
      )}
      */}
    </Container>
  )
}

export default Recipient
