import React from 'react'
import { ArticleSummaryDTO, FullSupportInformation } from '../../types'
import ArticleCard from '../../components/ArticleCard/ArticleCard'
import styled from 'styled-components'

const TabListWrapper = styled.div`
  width: calc(100%);
`
interface ArticlesTabProps {
  podcastImage: string
  podcastId: string
  articlesList: ArticleSummaryDTO[]
  podcastFullSupportInformation: FullSupportInformation
}

const ArticlesTab: React.FC<ArticlesTabProps> = ({
  podcastImage,
  articlesList,
  podcastId,
  podcastFullSupportInformation,
}) => {
  return (
    <TabListWrapper>
      {articlesList.map((article, idx) => (
        <ArticleCard
          podcastFullSupportInformation={podcastFullSupportInformation}
          podcastId={podcastId}
          id={article.id}
          title={article.title}
          description={article.description}
          visibility={article.visibility}
          userHasAccessToArticle={article.userHasAccessToArticle}
          image={article.image || podcastImage}
          publishedAt={article.publishedAt}
          state={article.state}
          idx={idx}
        />
      ))}
    </TabListWrapper>
  )
}

export default ArticlesTab
