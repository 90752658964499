import React, { useState } from 'react'
import BasePage from '../../../components/BasePage/BasePage'
import {
  BodyText,
  BodyTextWithMargin,
  ButtonMargin,
  CopyPixButtonWrapper,
  LoadingText,
  PageWrapper,
  PaymentButtonWrapper,
  PaymentDataWrapper,
  RedTitleText,
  SupporterBodyText,
  SupporterSubtitle,
  SupportPriceWrapper,
  TitleText,
  TitleTextWithMargin,
  WrapperWithMargin,
} from './Support.styled'
import { CircularProgress, Divider } from '@material-ui/core'
import Button from '../../../components/Button/Button'
import StyledNavLink from '../../../components/StyledNavLink'
import { getErrorPath } from '../../../Routes/RouteNames'
import {
  archiveArticle,
  cancelMonthlySupport,
  regeneratePix,
} from '../../../services/api'
import {
  createPathWithQueryParam,
  useGoToRoute,
  usePathName,
} from '../../../Routes/RouteAux'
import { isBefore, parse as parseDate } from 'date-fns'
import { Colors } from '../../../styles'
import copyToClipboard from '../../../services/copyToClipboard'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'

interface UserSupporterProps {
  signOut: () => Promise<void>
  supportId: string
  podcastTitle: string
  nextBillingDate: string
  creditCardNumber: string
  supportValue: string
  podcastId: string
  pixQRCodeUrl?: string
  pixQRCode?: string
  isSupporter?: boolean
}

const UserSupporter: React.FC<UserSupporterProps> = ({
  signOut,
  supportId,
  podcastTitle,
  supportValue,
  creditCardNumber,
  nextBillingDate,
  podcastId,
  pixQRCodeUrl,
  pixQRCode,
  isSupporter,
}) => {
  const [hasCanceledSupport, setHasCanceledSupport] = useState(false)
  const [isLoading, setIsloading] = useState(false)

  const goToRoute = useGoToRoute()

  const pathName = usePathName()
  const errorRoute = createPathWithQueryParam(getErrorPath(), {
    redirectTo: pathName,
  })

  const cancelSupport = () => {
    setIsloading(true)
    cancelMonthlySupport(supportId).then((res) => {
      if (res.error) {
        goToRoute(errorRoute)
      } else {
        setHasCanceledSupport(true)
      }
      setIsloading(false)
    })
  }

  const mainContent = () => {
    if (isLoading) {
      return (
        <PaymentDataWrapper>
          <CircularProgress />
          <LoadingText>Aguarde enquanto estamos processando...</LoadingText>
        </PaymentDataWrapper>
      )
    }

    if (hasCanceledSupport) {
      return (
        <PaymentDataWrapper>
          <BodyTextWithMargin>Seu apoio a</BodyTextWithMargin>
          <TitleText>{podcastTitle}</TitleText>
          <RedTitleText>foi cancelado</RedTitleText>
          <BodyTextWithMargin>
            Seu acesso a conteúdos e benefícios <br />
            está válido até dia {nextBillingDate}
          </BodyTextWithMargin>
          <StyledNavLink to={`/podcast/${podcastId}`}>
            <ButtonShadow
              variant="primary"
              label=" Voltar para página do creator"
            />
          </StyledNavLink>
        </PaymentDataWrapper>
      )
    }

    if (
      pixQRCodeUrl &&
      isBefore(parseDate(nextBillingDate, 'dd/MM/yyyy', Date.now()), Date.now())
    ) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
          }}
        >
          <BodyText>Você tem um pagamento de apoio pendente para</BodyText>
          <SupporterSubtitle>{podcastTitle}</SupporterSubtitle>
          <BodyText>no valor de</BodyText>
          <SupporterSubtitle>R${supportValue}</SupporterSubtitle>
          <Divider />
          <SupporterBodyText>
            Este pagamento venceu no dia {nextBillingDate}
          </SupporterBodyText>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <SupportPriceWrapper>
              <TitleTextWithMargin>Pix</TitleTextWithMargin>
              <BodyTextWithMargin>
                Acesse o aplicativo ou site do seu banco, selecione <b>Pix</b> e
                depois clique em <b>Pagar</b>
              </BodyTextWithMargin>
              <img
                src={pixQRCodeUrl}
                style={{ background: Colors.BRAND_PRIMARY, padding: '16px' }}
              />
              <CopyPixButtonWrapper>
                <ButtonShadow
                  onPress={() => {
                    copyToClipboard(pixQRCode).then(() => {
                      alert(`Código pix copiado!`)
                    })
                  }}
                  label="Copiar código pix"
                />
              </CopyPixButtonWrapper>
              <BodyTextWithMargin>
                Leia o QR Code no celular ou copie o código Pix no computador.
              </BodyTextWithMargin>
              <BodyTextWithMargin>
                O seu pagamento pode demorar alguns minutos para ser registrado
                em nosso sistema.
              </BodyTextWithMargin>
              <BodyTextWithMargin>
                Caso ocorra algum problema com código, clique para gerar uma
                nova chave:
              </BodyTextWithMargin>
              <CopyPixButtonWrapper>
                <ButtonShadow
                  variant="secondary"
                  onPress={async () => {
                    const res = await regeneratePix(supportId)
                    if (res.error) {
                      alert(
                        `Ocorreu um erro inesperado, por favor contate o suporte`,
                      )
                    } else {
                      window.location.reload()
                    }
                  }}
                  label="Gerar nova chave pix"
                />
              </CopyPixButtonWrapper>
            </SupportPriceWrapper>
          </div>

          <PaymentButtonWrapper>
            <StyledNavLink to={`/podcast/${podcastId}`}>
              <ButtonShadow
                variant="primary"
                label=" Voltar para página do creator"
              />
            </StyledNavLink>
          </PaymentButtonWrapper>
        </div>
      )
    }

    const isOverdue = typeof isSupporter === 'boolean' && !isSupporter
    return (
      <div>
        <BodyText>
          {isOverdue
            ? 'Seu apoio está atrasado para'
            : 'Você já possui um apoio ativo para'}
        </BodyText>
        <SupporterSubtitle>{podcastTitle}</SupporterSubtitle>
        <BodyText>no valor de</BodyText>
        <SupporterSubtitle>R${supportValue}</SupporterSubtitle>
        <Divider />
        {isOverdue ? (
          <SupporterBodyText>
            Houve um problema no seu pagamento.
          </SupporterBodyText>
        ) : null}
        <SupporterBodyText>
          {isOverdue
            ? `A próxima tentativa será no dia ${nextBillingDate},`
            : `Seu próximo pagamento é no dia ${nextBillingDate},`}
        </SupporterBodyText>
        {creditCardNumber && (
          <SupporterBodyText>no cartão {creditCardNumber}</SupporterBodyText>
        )}
        <PaymentButtonWrapper>
          <ButtonShadow
            variant="secondary"
            onPress={cancelSupport}
            label="Cancelar apoio"
            style={{ alignSelf: 'center' }}
          />
          <StyledNavLink to={`/podcast/${podcastId}`}>
            <ButtonShadow
              variant="primary"
              label="Voltar para página do creator"
            />
          </StyledNavLink>
        </PaymentButtonWrapper>
      </div>
    )
  }

  return (
    <BasePage signOut={signOut}>
      <PageWrapper>{mainContent()}</PageWrapper>
    </BasePage>
  )
}

export default UserSupporter
