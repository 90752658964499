import { LoginButton } from '@telegram-auth/react'
import ChatCard from 'components/ChatCard/ChatCard'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { LETTER_SPACING_1 } from 'styles/typography'
import { FullSupportInformation, ListenerTelegramChatDTO } from '../../types'
import { Config } from 'settings/config'
import {
  hasUserTelegramAccount,
  linkUserTelegramAccount,
  TelegramUser,
} from 'services/api'
import { CircularProgress, Snackbar } from '@material-ui/core'

const Wrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  min-width: 250px;
`

const Title = styled.h1`
  font-family: SpaceGrotesk;
  font-weight: 700;
  font-size: 26px;
  letter-spacing: -${LETTER_SPACING_1}px;
  width: 100%;
  text-align: center;
`

const TelegramLoginWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface ChatsTabProps {
  chats: ListenerTelegramChatDTO[]
  fetchChats: () => Promise<void>
  podcastFullSupportInformation: FullSupportInformation
}

const ChatsModal: React.FC<ChatsTabProps> = ({
  chats,
  fetchChats,
  podcastFullSupportInformation,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasTelegramAccount, setHasTelegramAccount] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const checkUserTelegramAccount = useCallback(async () => {
    setIsLoading(true)

    const result = await hasUserTelegramAccount()

    if (result.error) {
      setErrorMessage('Ocorreu um erro inesperado. Tente novamente.')
      setIsLoading(false)
      return
    }

    setHasTelegramAccount(result.data.hasTelegramAccount)
    setIsLoading(false)
  }, [])

  const handleLinkAccount = useCallback(
    async (user: TelegramUser) => {
      setIsLoading(true)

      const response = await linkUserTelegramAccount(user)

      if (response.error?.message === 'Account is linked with other user') {
        setErrorMessage('Essa conta já está vinculada com outro usuário.')
        setIsLoading(false)
        return
      }

      if (response.error) {
        setErrorMessage('Ocorreu um erro inesperado. Tente novamente.')
        setIsLoading(false)
        return
      }

      checkUserTelegramAccount()
    },
    [checkUserTelegramAccount],
  )

  useEffect(() => {
    checkUserTelegramAccount()
  }, [checkUserTelegramAccount])

  return (
    <Wrapper>
      <Title>Grupos</Title>

      {isLoading ? (
        <LoadingWrapper>
          <CircularProgress style={{ color: 'black' }} />
        </LoadingWrapper>
      ) : hasTelegramAccount ? (
        chats.map((chat, index) => (
          <ChatCard
            key={chat.id}
            chatId={chat.id}
            title={chat.title}
            userHasAccess={chat.userHasAccess}
            joined={chat.joined}
            fetchChats={fetchChats}
            index={index}
            podcastFullSupportInformation={podcastFullSupportInformation}
          />
        ))
      ) : (
        <TelegramLoginWrapper>
          <LoginButton
            onAuthCallback={handleLinkAccount}
            botUsername={Config.TELEGRAM_BOT_USERNAME}
            buttonSize="large"
            requestAccess="write"
            showAvatar
            lang="pt-br"
            widgetVersion={22}
          />
        </TelegramLoginWrapper>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!errorMessage}
        onClose={() => setErrorMessage(null)}
        message={errorMessage}
      />
    </Wrapper>
  )
}

export default ChatsModal
