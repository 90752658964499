import styled from 'styled-components'
import { Colors } from 'styles'
import { ReactComponent as TelegramIconSvg } from '../../assets/icons/telegram.svg'
import { LETTER_SPACING_1 } from 'styles/typography'

export const Wrapper = styled.div`
  padding: 20px 64px;

  @media (max-width: 600px) {
    padding: 16px;
  }
`

export const Title = styled.h1`
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: PP-Neue-Machina;
  font-weight: 800;
  font-size: 2.5rem;
  color: ${Colors.GREY[400]};
  margin-top: 16px;
`

export const TelegramIcon = styled(TelegramIconSvg)`
  width: 40px;
  height: 40px;
`

export const LinkedAccount = styled.div``

export const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  align-items: center;
  color: ${Colors.GREY[400]};
`

export const MessageWrapper = styled.div`
  font-family: PP-Neue-Machina;
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const SyncButton = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  font-family: PP-Neue-Machina;
  font-weight: 800;
  font-size: 1rem;
  color: ${Colors.PURPLE[400]};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const ChatsInfo = styled.p`
  font-family: SpaceGrotesk;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -${LETTER_SPACING_1}px;
  background-color: ${Colors.GREY[200]};
  color: ${Colors.WHITE};
  padding: 8px 18px;
  width: fit-content;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

export const BotLink = styled.button`
  color: ${Colors.PURPLE[400]};
  text-decoration: underline;
  background: transparent;
  border: none;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  cursor: pointer;
  padding: 0 2px;
`

export const EmptyChatsText = styled.p`
  font-family: SpaceGrotesk;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -${LETTER_SPACING_1}px;
  width: fit-content;
  color: ${Colors.GREY[200]};
`

export const InformativeText = styled.p`
  max-width: 700px;
  font-family: SpaceGrotesk;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -${LETTER_SPACING_1}px;

  & p {
    margin-top: 0;
    margin-bottom: 6px;
  }

  & ul {
    padding: 0 20px;
    margin: 0;
  }
`

export const AddBotButtonContainer = styled.div`
  width: fit-content;
`
