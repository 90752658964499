import { Select } from '@material-ui/core'
import styled from 'styled-components'
import { Colors } from 'styles'
import { LETTER_SPACING_1 } from 'styles/typography'
import { ReactComponent as TelegramIconSvg } from '../../assets/icons/telegram.svg'

export const CardContainer = styled.div<{ isOdd?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  background-color: ${({ isOdd }) => (isOdd ? Colors.GREY[50] : Colors.WHITE)};
  padding: 8px 0px;
  max-width: 1024px;

  @media (max-width: 900px) {
    gap: 10px;
    align-items: flex-start;
    flex-direction: column;
  }
`

export const Cover = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    display: none;
  }
`

export const TelegramIcon = styled(TelegramIconSvg)``

export const Title = styled.span`
  font-family: SpaceGrotesk;
  margin-left: 16px;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -${LETTER_SPACING_1}px;
`

export const Id = styled.span`
  font-family: SpaceGrotesk;
  margin-left: 16px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -${LETTER_SPACING_1}px;
  line-height: calc(18px * 1.5);
  color: ${Colors.LIGHTER_GRAY};
  align-self: baseline;
`

export const Actions = styled.div`
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Button = styled.button<{
  variant: 'link' | 'linked' | 'unlink' | 'alert'
  defaultCursor?: true
}>`
  background-color: ${({ variant }) =>
    ({
      link: Colors.PURPLE[400],
      linked: Colors.SUPPORT_SUCCESS,
      unlink: Colors.SUPPORT_ERROR,
      alert: Colors.SUPPORT_ALERT,
    }[variant])};
  color: ${({ variant }) =>
    ({
      link: Colors.WHITE,
      linked: Colors.WHITE,
      unlink: Colors.WHITE,
      alert: Colors.GREY[400],
    }[variant])};
  cursor: ${({ defaultCursor }) => (defaultCursor ? 'default' : 'pointer')};

  border: none;
  font-family: PP-Neue-Machina;
  font-weight: 800;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 20px;
  border-radius: 32px;
  line-height: calc(1rem * 1.5);

  &:hover {
    opacity: ${({ defaultCursor }) => (defaultCursor ? 1 : 0.8)};
  }
`

export const StyledSelect = styled(Select).attrs({ variant: 'outlined' })`
  &.MuiInputBase-root {
    border-radius: 32px;

    .MuiSelect-root {
      padding: 10px 32px 10px 20px;

      font-family: PP-Neue-Machina;
      font-weight: 800;
      font-size: 1rem;
    }

    .MuiSelect-select:focus {
      background-color: transparent;
    }
  }
`
