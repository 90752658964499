import { Snackbar, TextField } from '@material-ui/core'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Info from '@mui/icons-material/Info'
import LaunchIcon from '@mui/icons-material/Launch'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import { QRCodeSVG } from 'qrcode.react'
import React, { useCallback, useRef, useState } from 'react'
import openURL from 'utils/openURL'
import * as Styled from './JoinChatInstructions.styled'

interface JoinChatInstructionsProps {
  inviteLink?: string
}

const JoinChatInstructions: React.FC<JoinChatInstructionsProps> = ({
  inviteLink,
}) => {
  const [copyTextResult, setCopyTextResult] = useState<string | undefined>(
    undefined,
  )
  const inputUrlRef = useRef<HTMLInputElement>(null)

  const handleCopyLink = useCallback(async () => {
    inputUrlRef.current?.select()
    const success = document.execCommand('copy')
    setCopyTextResult(success ? 'Link Copiado!' : 'Erro ao copiar link')
    inputUrlRef.current?.blur()
  }, [])

  const handleOpenLink = useCallback(async () => {
    await openURL(inviteLink)
  }, [inviteLink])

  return (
    <Styled.Container>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!copyTextResult}
        onClose={() => setCopyTextResult(undefined)}
        message={copyTextResult}
        autoHideDuration={3000}
      />

      <Styled.Title>
        Acesse o grupo escaneando o QR Code abaixo ou utilizando o link.
      </Styled.Title>

      <Styled.Instructions>
        <Info fontSize="medium" />
        <span>
          Código de uso único. Confira a sua conta e não compartilhe com outras
          pessoas!
        </span>
      </Styled.Instructions>

      <Styled.QrCodeContainer>
        <QRCodeSVG value={inviteLink} size={256} marginSize={4} />
      </Styled.QrCodeContainer>

      <Styled.LinkContainer>
        <TextField
          type="text"
          value={inviteLink}
          onChange={() => {}}
          variant="outlined"
          fullWidth
          inputRef={inputUrlRef}
        />

        <Styled.LinkActions>
          <ButtonShadow
            leftIcon={<ContentCopyIcon style={{ color: 'white' }} />}
            onPress={handleCopyLink}
          />
          <ButtonShadow
            leftIcon={<LaunchIcon style={{ color: 'white' }} />}
            onPress={handleOpenLink}
          />
        </Styled.LinkActions>
      </Styled.LinkContainer>
    </Styled.Container>
  )
}

export default JoinChatInstructions
