import React, { Fragment, useCallback, useState } from 'react'
import {
  createPathWithQueryParam,
  useGoToRoute,
  usePathName,
} from '../../Routes/RouteAux'
import Dialog from '../Dialog/Dialog'
import * as Styled from './ChatCard.styled'

import CheckIcon from '@mui/icons-material/Check'
import { CircularProgress, Snackbar } from '@material-ui/core'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import { getLoginPath, getPaymentsPath } from '../../Routes/RouteNames'
import { useTypedSelector } from 'reducers'
import { getTelegramChatInvite } from 'services/api'
import JoinChatInstructions from 'components/JoinChatInstructions/JoinChatInstructions'
import { FullSupportInformation } from 'types'

export interface ChatCardProps {
  chatId: string
  title: string
  userHasAccess: boolean
  joined: boolean
  fetchChats: () => Promise<void>
  index: number
  podcastFullSupportInformation: FullSupportInformation
}

const ChatCard: React.FC<ChatCardProps> = ({
  chatId,
  title,
  userHasAccess,
  joined,
  fetchChats,
  index,
  podcastFullSupportInformation,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  )
  const [isJoinChatInstructionsOpen, setIsJoinChatInstructionsOpen] =
    useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [inviteLink, setInviteLink] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [isJoined, setIsJoined] = useState(joined)
  const goToRoute = useGoToRoute()
  const currentProfile = useTypedSelector(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const pathName = usePathName()
  const loginRoute = createPathWithQueryParam(getLoginPath(), {
    redirectTo: pathName,
  })

  const handlePress = useCallback(async () => {
    if (!userHasAccess || !currentProfile) {
      setIsModalOpen(true)
      return
    }

    setIsLoading(true)
    const response = await getTelegramChatInvite(chatId)

    if (response.error?.message === 'Already joined in chat') {
      setErrorMessage('Você já faz parte deste grupo.')
      setIsJoined(true)
      setIsLoading(false)
      return
    }

    if (response.error) {
      setErrorMessage('Falha ao entrar no chat. Tente novamente.')
      setIsLoading(false)
      return
    }

    setInviteLink(response.data.link)
    setIsJoinChatInstructionsOpen(true)
    setIsLoading(false)
  }, [userHasAccess, currentProfile, chatId])

  const handleGoToSupport = useCallback(() => {
    goToRoute(
      getPaymentsPath(
        podcastFullSupportInformation.supportSummary.podcastId,
        podcastFullSupportInformation.supportTiers[0].id,
      ),
    )
    setIsModalOpen(false)
  }, [goToRoute, podcastFullSupportInformation])

  const handleGoToLogin = useCallback(() => {
    goToRoute(loginRoute)
  }, [loginRoute, goToRoute])

  const handleCloseJoinChatInstructions = useCallback(() => {
    setIsJoinChatInstructionsOpen(false)
    fetchChats()
  }, [fetchChats])

  const buttonLabel = isJoined ? 'Entrou' : userHasAccess ? 'Entrar' : 'Apoiar'

  const buttonIcon = isJoined ? (
    <CheckIcon fontSize="small" style={{ color: 'white' }} />
  ) : isLoading ? (
    <CircularProgress size={16} style={{ color: 'white' }} />
  ) : undefined

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!errorMessage}
        onClose={() => setErrorMessage(undefined)}
        message={errorMessage}
      />

      <Dialog
        dialogText={<JoinChatInstructions inviteLink={inviteLink} />}
        handleCloseDialog={handleCloseJoinChatInstructions}
        isDialogOpen={isJoinChatInstructionsOpen}
        noActionText="ok"
      />

      <Dialog
        isDialogOpen={isModalOpen}
        handleCloseDialog={() => setIsModalOpen(false)}
        dialogText={
          currentProfile
            ? 'Chat exclusivo para apoiadores'
            : 'Você precisa estar logado'
        }
        dialogActionButtonText={currentProfile ? 'Apoiar podcast' : 'Login'}
        onClickDialogActionButton={
          currentProfile ? handleGoToSupport : handleGoToLogin
        }
        noActionText="ok"
      />

      <Styled.CardContainer isOdd={index % 2 === 1}>
        <Styled.Cover>
          <Styled.TelegramIcon />
        </Styled.Cover>

        <Styled.Title>
          {title} {!userHasAccess && <Styled.PadlockWithMargin />}
        </Styled.Title>

        <Styled.Actions>
          <ButtonShadow
            onPress={handlePress}
            variant="primary"
            size="small"
            disabled={isLoading || isJoined}
            label={buttonLabel}
            leftIcon={buttonIcon}
          />
        </Styled.Actions>
      </Styled.CardContainer>
    </Fragment>
  )
}

export default ChatCard
