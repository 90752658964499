import { Config } from '../settings/config'
import * as TYPES from '../types'
import { AddressProps, SupportTier } from '../types'
import { ApiFetch } from './ApiFetch'
import getMonthText from 'utils/getMonthText'
import * as monitor from 'services/monitor'
import 'abortcontroller-polyfill'
import { store } from '../App'
import * as R from 'ramda'

const apiBaseUrl = `${Config.API_URL}/api/v1`

const AbortController = window.AbortController

let searchPodcastsController: AbortController
let searchEpisodesController: AbortController
let searchProfilesController: AbortController

export const buildQuerystring = (query: any): string =>
  Object.keys(query)
    .map((key) => `${key}=${query[key]}`)
    .join('&')

export const getApiMethod = (url: string): Promise<ApiFetch> => {
  // console.log(url)
  // console.log(encodeURI(url))
  return ApiFetch.get(`${encodeURI(url)}`)
}

//MOBILE_CONFIG
export const listSubscriptionPlans = async (params: {
  os: string
  appVersion: string
  buildVersion: string
  bundleIdentifier: string
}): Promise<ApiFetch> =>
  ApiFetch.get(
    `${apiBaseUrl}/mobileConfigs/plans?${ApiFetch.toQueryString(params)}`,
  )

export const getFeatureFlagsConfig = async (params: {
  os: string
  appVersion: string
  buildVersion: string
  bundleIdentifier: string
}): Promise<ApiFetch> =>
  ApiFetch.get(
    `${apiBaseUrl}/mobileConfigs/featureFlags?${ApiFetch.toQueryString(
      params,
    )}`,
  )

export const getSupportValues = async (): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/mobileConfigs/supportValues`)

export const getCategories = async (): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/mobileConfigs/categories`)

//BILLING
export const createSubscription = async (
  body: TYPES.CreateSubscriptionBody,
): Promise<ApiFetch> =>
  ApiFetch.post(`${apiBaseUrl}/billing/subscriptions`, body)

export const cancelSubscription = (subscriptionId: string): Promise<ApiFetch> =>
  ApiFetch.del(`${apiBaseUrl}/billing/subscriptions/${subscriptionId}`)

export const editSubscription = (
  subscriptionId: string,
  body: {
    podcastId: string
    paymentMethodId: string
    paymentMethodType: string
  },
): Promise<ApiFetch> =>
  ApiFetch.put(`${apiBaseUrl}/billing/subscriptions/${subscriptionId}`, body)

//USERS
export const updateUser = (body: {
  avatar?: string
  name?: string
  secondaryEmail?: string
  address?: AddressProps
  phone?: string
}): Promise<ApiFetch> => ApiFetch.put(`${apiBaseUrl}/users/mySelf`, body)

export const getProfiles = (): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/users/profiles`)

export const podcastsRecommendedForSponsorship = (): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/content/podcasts/recommendations/sponsorship`)

export const getPodcastDetails = async (podcastId: string): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/content/podcasts/${podcastId}`)

export const getPodcastEpisodes = async (
  podcastId: string,
  page?: number,
  includeUnreleased?: boolean,
  releaseSort?: 'asc' | 'desc',
  includePlaysCount?: boolean,
  searchTerm?: string,
): Promise<ApiFetch> => {
  const params = []

  if (page) params['page'] = page
  if (includeUnreleased) params['includeUnreleased'] = includeUnreleased
  if (releaseSort) params['releaseSort'] = releaseSort
  if (includePlaysCount) params['includePlaysCount'] = includePlaysCount
  if (searchTerm) params['searchTerm'] = searchTerm

  return ApiFetch.get(
    `${apiBaseUrl}/content/episodes/podcasts/${podcastId}?${buildQuerystring(
      params,
    )}`,
  )
}

export const getPodcastEpisodesByUrlPathName = async (
  podcastUrlPathName: string,
  page?: number,
): Promise<ApiFetch> =>
  ApiFetch.get(
    `${apiBaseUrl}/content/episodes/podcasts/pathname/${podcastUrlPathName}${
      page ? `?page=${page}` : ''
    }`,
  )

export const unauthenticatedGetPodcastEpisodesByUrlPathName = async (
  podcastUrlPathName: string,
  page?: number,
): Promise<ApiFetch> =>
  ApiFetch.get(
    `${apiBaseUrl}/content/episodes/podcasts/pathname/${podcastUrlPathName}/unauthenticated${
      page ? `?page=${page}` : ''
    }`,
  )

export const unauthenticatedGetPodcastEpisodesById = async (
  podcastId: string,
  page?: number,
): Promise<ApiFetch> =>
  ApiFetch.get(
    `${apiBaseUrl}/content/episodes/podcasts/${podcastId}/unauthenticated${
      page ? `?page=${page}` : ''
    }`,
  )

type PlayedTime = {
  currentTime: number
  duration: number
  totalPlayedTime: number
}
export const updatePlayedTime = async (
  episodeId: string,
  playedTime: PlayedTime,
): Promise<any> => {
  await ApiFetch.put(
    `${apiBaseUrl}/content/episodes/${episodeId}/updatePlayedTime`,
    playedTime,
  )
}

export const getPlaylistsFromPodcastProfile = async (
  podcastId: string,
  page?: number,
  includeEpisodes?: boolean,
): Promise<ApiFetch> => {
  const params = []

  if (page) params['page'] = page
  if (includeEpisodes) params['includeEpisodes'] = includeEpisodes

  return ApiFetch.get(
    `${apiBaseUrl}/content/playlists/podcast/${podcastId}?${buildQuerystring(
      params,
    )}`,
  )
}

export const getPodcastPlaylists = async (
  podcastId: string,
  page?: number,
): Promise<ApiFetch> => {
  const params = []

  if (page) params['page'] = page

  return ApiFetch.get(
    `${apiBaseUrl}/content/podcasts/${podcastId}/playlists?${buildQuerystring(
      params,
    )}`,
  )
}

export const getPodcastPlaylistsByUrlPathName = async (
  urlPathName: string,
): Promise<ApiFetch> =>
  ApiFetch.get(
    `${apiBaseUrl}/content/playlists/urlPathName/podcast/${urlPathName}`,
  )

export const authenticatedGetPlaylistEpisodes = async (
  playlistId: string,
): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/content/episodes/playlists/${playlistId}`)

export const unauthenticatedGetPlaylistEpisodes = async (
  playlistId: string,
): Promise<ApiFetch> =>
  ApiFetch.get(
    `${apiBaseUrl}/content/episodes/playlists/${playlistId}/unauthenticated`,
  )

export const getEpisodeDetails = async (episodeId: string): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/content/episodes/${episodeId}`)

export const getNextEpisode = async (
  episodeId: string,
  podcastId: string,
): Promise<undefined | TYPES.Episode> => {
  const res = await ApiFetch.get(
    `${apiBaseUrl}/content/episodes/${episodeId}/next?context=podcast&contextId=${podcastId}`,
  )
  if (res.hasFailed()) return undefined
  if (!res.data) return undefined
  return res.data
}

export const getBrandProfile = async (brandId: string): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/brands/${brandId}`)

export const getBrandProductions = async (brandId: string): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/content/podcasts/productions?brandId=${brandId}`)

export const getAdvertisment = async (): Promise<
  undefined | TYPES.Advertisement
> => {
  const res = await ApiFetch.get(`${apiBaseUrl}/dsp/advertisements`)
  if (res.error && res.error.message !== 'No advertisement available') {
    monitor.notify(res.error)
  }
  return res.hasFailed() ? undefined : res.data
}

export const markAdvertisementPlayed = async (
  advertisementId: string,
): Promise<void> => {
  await ApiFetch.post(
    `${apiBaseUrl}/dsp/advertisements/${advertisementId}/played`,
  )
  return
}

export const getPaginatedPodcastFollowers = async (
  podcastId: string,
  page?: number,
  searchTerm?: string,
): Promise<ApiFetch> => {
  const params = []

  if (page) params['page'] = page
  if (searchTerm) params['searchTerm'] = searchTerm

  return await ApiFetch.get(
    `${apiBaseUrl}/social/podcasts/${podcastId}?${buildQuerystring(params)}`,
  )
}

export const getPaginatedPodcastSupporters = async (
  podcastId: string,
  page?: number,
  searchTerm?: string,
): Promise<ApiFetch> => {
  const params = []

  if (page) params['page'] = page
  if (searchTerm) params['searchTerm'] = searchTerm

  return await ApiFetch.get(
    `${apiBaseUrl}/billing/supports/podcasts/${podcastId}?${buildQuerystring(
      params,
    )}`,
  )
}

export const followPodcast = async (podcastId: string): Promise<ApiFetch> =>
  ApiFetch.post(`${apiBaseUrl}/social/podcasts/${podcastId}/follows`)

export const unfollowPodcast = async (podcastId: string): Promise<ApiFetch> =>
  ApiFetch.del(`${apiBaseUrl}/social/podcasts/${podcastId}/follows`)

export const getShareLink = async (
  body: TYPES.GetShareLinkBody,
): Promise<ApiFetch> => ApiFetch.post(`${apiBaseUrl}/content/shareLink`, body)

export const markPodcastShared = async (podcastId: string): Promise<any> =>
  ApiFetch.post(`${apiBaseUrl}/metrics/podcasts/${podcastId}/shared`)

export const markEpisodeShared = async (episodeId: string): Promise<any> =>
  ApiFetch.post(`${apiBaseUrl}/metrics/episodes/${episodeId}/shared`)

export const markPodcastView = async (podcastId: any): Promise<any> =>
  ApiFetch.post(`${apiBaseUrl}/metrics/podcasts/${podcastId}/view`)

// TYPES.PodcastSummary[]
export const getFollowedPodcasts = async (): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/content/podcasts`)

// HomeContent[]
export const getHomeContent = async (): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/content/home`)

export const getListenerProfile = async (userId: string): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/users/${userId}`)

type userIdOrBrandId = {
  userId?: string
  podcastId?: string
  brandId?: string
}
export const getSupportedPodcastsByProfile = async ({
  userId,
  brandId,
}: userIdOrBrandId): Promise<ApiFetch> =>
  ApiFetch.get(
    `${apiBaseUrl}/content/podcasts/supports/profiles/${userId || brandId}`,
  )

export const getFollowedPodcastsByProfile = async ({
  userId,
  podcastId,
  brandId,
}: userIdOrBrandId): Promise<ApiFetch> =>
  ApiFetch.get(
    `${apiBaseUrl}/content/podcasts/following?${
      userId
        ? `userId=${userId}`
        : brandId
        ? `brandId=${brandId}`
        : `podcastId=${podcastId}`
    }`,
  )

export const getUserSupports = async (podcastId: string): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/billing/supports/details?podcastId=${podcastId}`)

export const upsertBillingAddress = async (
  billingAddress: any,
): Promise<ApiFetch> =>
  ApiFetch.post(`${apiBaseUrl}/billing/address`, billingAddress)

export const getBillingAddress = async (): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/billing/address`)

export const createUserPaymentMethod = async (
  paymentMethod: any,
  recaptchaToken: string,
): Promise<ApiFetch> =>
  ApiFetch.post(`${apiBaseUrl}/billing/paymentMethods`, {
    ...paymentMethod,
    recaptchaToken,
  })

export const userRequestAccountDelete = (): Promise<ApiFetch> =>
  ApiFetch.post(`${apiBaseUrl}/users/requestAccountDelete`)

export const deleteUserPaymentMethod = async (
  paymentMethodId: string,
): Promise<ApiFetch> =>
  ApiFetch.del(`${apiBaseUrl}/billing/paymentMethods/${paymentMethodId}`)

export const getUserPaymentMethods = async (): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/billing/paymentMethods`)

export const getUserAddress = async (): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/billing/address`)

//CreateSupportDTO
export interface CreateSupportInterface {
  podcastId: string
  paymentMethodType: string
  paymentMethodId: string
  value: string
  documentNumber?: string
  recaptchaToken: string
}

export const createSupport = (
  transactionBody: CreateSupportInterface,
): Promise<ApiFetch> =>
  ApiFetch.post(`${apiBaseUrl}/billing/supports`, {
    ...transactionBody,
    ...{
      document: {
        type: 'cpf',
        number: transactionBody.documentNumber,
      },
    },
  })

export const createTipCharge = async (
  transactionBody: CreateSupportInterface,
): Promise<ApiFetch> =>
  ApiFetch.post(`${apiBaseUrl}/billing/tips`, transactionBody)

//CreateSupportDTO
export const createTip = (transactionBody: any): Promise<ApiFetch> =>
  ApiFetch.post(`${apiBaseUrl}/billing/tips`, transactionBody)

//TODO
export const cancelMonthlySupport = (supportId: string): Promise<ApiFetch> =>
  ApiFetch.del(`${apiBaseUrl}/billing/supports/${supportId}`)

export const listTipsByUserByPodcast = async (
  userId: string,
  podcastId: string,
): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/billing/tips/${userId}/podcast/${podcastId}`)

//TODO
//TYPES.PodcastSummary[]
// export const searchPodcasts = async (query: string): Promise<ApiFetch> =>
export const searchPodcasts = async (search: string): Promise<ApiFetch> => {
  if (searchPodcastsController !== undefined) {
    searchPodcastsController.abort()
  }

  searchPodcastsController = new AbortController()
  const signal = searchPodcastsController.signal

  return ApiFetch.get(
    `${apiBaseUrl}/content/podcasts/search?search=${search}`,
    signal,
  )
}

export const getSupportInformationByUrlPathName = async (
  podcastUrlPathName: string,
): Promise<ApiFetch> => {
  return ApiFetch.get(
    `${apiBaseUrl}/content/podcast/fullSupportInformation/${podcastUrlPathName}`,
  )
}

export const getSupportInformationByPodcastId = async (
  podcastId: string,
): Promise<ApiFetch> => {
  return ApiFetch.get(
    `${apiBaseUrl}/content/podcast/fullSupportInformation/id/${podcastId}`,
  )
}

export const getFeed = async (
  podcastId: string,
  lastDateTimestamp: string | number,
  includePlays?: boolean,
): Promise<ApiFetch> => {
  return ApiFetch.get(
    `${apiBaseUrl}/content/podcast/${podcastId}/lastContent?startDate=${lastDateTimestamp}${
      includePlays ? `&includePlays=true` : ''
    }`,
  )
}

export const getTelegramLinkedChats = async (
  podcastId: string,
): Promise<ApiFetch> => {
  return ApiFetch.get(
    `${apiBaseUrl}/content/podcast/${podcastId}/telegram-chats/linked`,
  )
}

export const getPodcastArticlesList = async (
  podcastId: string,
  includeDrafts?: boolean,
  page?: number,
  searchTerm?: string,
  createdSort?: string,
): Promise<ApiFetch> => {
  const params = []

  if (page) params['page'] = page
  if (searchTerm) params['searchTerm'] = searchTerm
  if (includeDrafts) params['includeDrafts'] = includeDrafts
  if (createdSort) params['createdSort'] = createdSort

  return ApiFetch.get(
    `${apiBaseUrl}/content/articles/podcast/${podcastId}?${buildQuerystring(
      params,
    )}`,
  )
}

export const getPodcastArticlesListUnauthenticated = async (
  podcastId: string,
): Promise<ApiFetch> => {
  return ApiFetch.get(
    `${apiBaseUrl}/content/articles/podcast/${podcastId}/unauthenticated`,
  )
}

export const getPodcastArticlesListByUrlPathName = async (
  podcastUrlPathName: string,
): Promise<ApiFetch> => {
  return ApiFetch.get(
    `${apiBaseUrl}/content/articles/podcast/pathname/${podcastUrlPathName}`,
  )
}

export const updateSupportDescription = async (
  podcastId: string,
  supportDescription: string,
): Promise<ApiFetch> => {
  const body = {
    supportDescription: supportDescription,
  }

  return ApiFetch.put(
    `${apiBaseUrl}/content/podcasts/${podcastId}/updateSupportDescription`,
    body,
  )
}

export const updateSupportTiers = async (
  podcastId: string,
  supportTiers: SupportTier[],
): Promise<ApiFetch> => {
  const body = {
    supportTiers: supportTiers,
  }

  return ApiFetch.put(`${apiBaseUrl}/billing/supportTiers/${podcastId}`, body)
}

export const updateSupportTier = async (
  podcastId: string,
  supportTierId: string,
  supportTier: SupportTier,
): Promise<ApiFetch> => {
  return ApiFetch.put(
    `${apiBaseUrl}/billing/supportTier/${podcastId}/${supportTierId}`,
    supportTier,
  )
}

export const deleteSupportTier = async (
  podcastId: string,
  supportTierId: string,
): Promise<ApiFetch> => {
  return ApiFetch.del(
    `${apiBaseUrl}/billing/supportTier/${podcastId}/${supportTierId}`,
  )
}

export const createSupportTier = async (
  podcastId: string,
  supportTier: SupportTier,
): Promise<ApiFetch> => {
  return ApiFetch.post(
    `${apiBaseUrl}/billing/supportTiers/${podcastId}`,
    supportTier,
  )
}

export type ArticleUpdateProps = {
  title: string
  description: string
  body: string
  image?: string
}

export const updateArticle = async (
  articleId: string,
  articleProps: ArticleUpdateProps,
): Promise<ApiFetch> => {
  const body = {
    ...articleProps,
  }

  return ApiFetch.put(`${apiBaseUrl}/content/article/${articleId}`, body)
}

export const updateDraftArticle = async (
  articleId: string,
  articleProps: ArticleUpdateProps,
): Promise<ApiFetch> => {
  const body = {
    ...articleProps,
  }

  return ApiFetch.put(`${apiBaseUrl}/content/article/${articleId}/draft`, body)
}

export const publishArticle = async (
  articleId: string,
  notifyOnPublish: boolean,
  visibility: string,
): Promise<ApiFetch> => {
  return ApiFetch.put(`${apiBaseUrl}/content/article/${articleId}/publish`, {
    notifyOnPublish,
    visibility,
  })
}

export type TopicCreationProps = {
  title: string
  body: string
  topicId: string
}

export const createTopic = async (
  podcastId: string,
  topicProps: TopicCreationProps,
): Promise<ApiFetch> => {
  const body = {
    ...topicProps,
  }
  return ApiFetch.post(`${apiBaseUrl}/content/podcast/${podcastId}/topic`, body)
}

export const deleteTopic = async (
  podcastId: string,
  topicId: string,
): Promise<ApiFetch> => {
  return ApiFetch.del(`${apiBaseUrl}/content/topic/${podcastId}/${topicId}`)
}

export const deleteComment = async (
  podcastId: string,
  commentId: string,
): Promise<ApiFetch> => {
  return ApiFetch.del(`${apiBaseUrl}/content/comment/${podcastId}/${commentId}`)
}

export const archiveArticle = async (articleId: string): Promise<ApiFetch> => {
  return ApiFetch.del(`${apiBaseUrl}/content/article/${articleId}`)
}

export const regeneratePix = async (supportId: string): Promise<ApiFetch> => {
  return ApiFetch.post(
    `${apiBaseUrl}/billing/support/${supportId}/regeneratePix`,
  )
}

export const archiveEpisode = async (
  podcastId: string,
  episodeId: string,
): Promise<ApiFetch> => {
  return ApiFetch.del(
    `${apiBaseUrl}/content/podcasts/${podcastId}/episodes/${episodeId}`,
  )
}

export type ArticleCreationProps = {
  title: string
  description: string
  body: string
  image?: string
  state: 'draft'
  visibility: 'open' | 'followers' | 'supporters'
  notifyOnPublish: boolean
}

export const createArticle = async (podcastId: string): Promise<ApiFetch> => {
  return ApiFetch.post(`${apiBaseUrl}/content/podcast/${podcastId}/article`)
}

export type PodcastProfileCreationProps = {
  podcastUrlPathName: string
  podcastTitle: string
  userCpf: string
  optInTermsOfUse: boolean
}

export const createPodcastProfile = async (
  podcastProfileProps: PodcastProfileCreationProps,
): Promise<ApiFetch> => {
  const body = {
    ...podcastProfileProps,
  }

  return ApiFetch.post(`${apiBaseUrl}/content/userPodcast/create`, body)
}

export type UpdatePodcastInfoProps = {
  podcastInfo?: {
    title?: string
    description?: string
    email?: string
    categories?: string[]
    types?: string[]
  }
  receivingInfo?: {
    userCpf?: string
    userCnpj?: string
    companyName: string
    phone?: string
    inclusionLetter?: boolean
    address?: {
      streetAddress?: string
      number?: string
      complement?: string
      city?: string
      country?: string
      state?: string
      zipCode?: string
    }
  }
}

export const updatePodcastInfo = async (
  podcastId: string,
  podcastProfileProps: UpdatePodcastInfoProps,
): Promise<ApiFetch> => {
  const body = {
    ...podcastProfileProps,
  }

  return ApiFetch.post(
    `${apiBaseUrl}/content/podcast/${podcastId}/updatePodcastInfo`,
    body,
  )
}

export type UpdatePodcastForumVisibilityProps = {
  forumVisibility: 'open' | 'followers' | 'supporters'
  forumTopicCreation: 'podcastTeam' | 'followers' | 'supporters'
}

export const updatePodcastForumSettings = async (
  podcastId: string,
  updatePodcastForumVisibilityProps: UpdatePodcastForumVisibilityProps,
): Promise<ApiFetch> => {
  const body = {
    ...updatePodcastForumVisibilityProps,
  }

  return ApiFetch.put(
    `${apiBaseUrl}/content/podcasts/${podcastId}/updateForumSettings`,
    body,
  )
}

export const verifyPathName = async (pathName: string): Promise<ApiFetch> => {
  return ApiFetch.get(
    `${apiBaseUrl}/content/podcast/urlPathNameValid/${pathName}`,
  )
}

export const getArticle = async (articleId: string): Promise<ApiFetch> => {
  return ApiFetch.get(`${apiBaseUrl}/content/article/${articleId}`)
}

export const uploadArticleImage = async (
  podcastId: string,
  articleImage: File,
): Promise<ApiFetch> => {
  return ApiFetch.upload(
    `${apiBaseUrl}/content/podcast/${podcastId}/image/article`,
    articleImage,
  )
}

export const uploadEpisodeAudio = async (
  podcastId: string,
  episodeId: string,
  file: File,
  onProgress?: (total: number, loaded: number) => void,
  abortSignal?: AbortSignal,
): Promise<ApiFetch> => {
  return ApiFetch.upload(
    `${apiBaseUrl}/content/podcasts/${podcastId}/episodes/audio`,
    file,
    {
      type: file.type,
      size: file.size,
    },
    undefined,
    undefined,
    onProgress,
    abortSignal,
  )
}

export const uploadEpisodeImage = async (
  podcastId: string,
  episodeId: string,
  file: File,
): Promise<ApiFetch> => {
  return ApiFetch.upload(
    `${apiBaseUrl}/content/podcasts/${podcastId}/episodes/images/image`,
    file,
    {
      type: file.type,
      size: file.size,
    },
  )
}

export const uploadPodcastImage = async (
  podcastId: string,
  file: File,
  imageType: 'image' | 'thumbnail' | 'banner',
): Promise<ApiFetch> => {
  return ApiFetch.upload(
    `${apiBaseUrl}/content/podcasts/${podcastId}/images/${imageType}`,
    file,
    {
      imageType,
    },
  )
}

export const uploadTopicImage = async (
  podcastId: string,
  topicImage: File,
  impersonateId?: { podcastId?: string; brandId?: string },
  userToken?: string,
): Promise<ApiFetch> => {
  return ApiFetch.upload(
    `${apiBaseUrl}/content/podcast/${podcastId}/image/topic`,
    topicImage,
    null,
    impersonateId,
    userToken,
  )
}

export const uploadSupportImage = async (
  podcastId: string,
  topicImage: File,
): Promise<ApiFetch> => {
  return ApiFetch.upload(
    `${apiBaseUrl}/billing/support/${podcastId}/image`,
    topicImage,
  )
}

export const markArticlePageView = async (
  articleId: string,
): Promise<ApiFetch> => {
  return ApiFetch.post(`${apiBaseUrl}/metrics/articles/${articleId}/view`)
}

export const getPodcastPayments = async (
  podcastId: string,
): Promise<ApiFetch> => {
  return ApiFetch.get(`${apiBaseUrl}/billing/payments/${podcastId}`)
}

//TODO
//TYPES.SearchProfile[]
// export const searchProfiles = async (query: string): Promise<ApiFetch> =>
export const searchProfiles = async (search: string): Promise<ApiFetch> => {
  if (searchProfilesController !== undefined) {
    searchProfilesController.abort()
  }

  searchProfilesController = new AbortController()
  const signal = searchProfilesController.signal

  return ApiFetch.get(
    `${apiBaseUrl}/search?resultType=profile&search=${search}`,
    signal,
  )
}

//TODO
//TYPES.Episode[]
// export const searchEpisodes = async (query: string): Promise<ApiFetch> =>
export const searchEpisodes = async (search: string): Promise<ApiFetch> => {
  if (searchEpisodesController !== undefined) {
    searchEpisodesController.abort()
  }

  searchEpisodesController = new AbortController()
  const signal = searchEpisodesController.signal

  return ApiFetch.get(
    `${apiBaseUrl}/search?resultType=episode&search=${search}`,
    signal,
  )
}

export const getListenedEpisodes = ({
  userId,
  brandId,
}: userIdOrBrandId): Promise<ApiFetch> => {
  return ApiFetch.get(
    `${apiBaseUrl}/content/episodes/history?${
      userId ? `userId=${userId}` : brandId ? `brandId=${brandId}` : ''
    }`,
  )
}

export const getLikedEpisodes = ({
  userId,
  brandId,
}: userIdOrBrandId): Promise<ApiFetch> => {
  return ApiFetch.get(
    `${apiBaseUrl}/content/episodes/liked?${
      userId ? `userId=${userId}` : brandId ? `brandId=${brandId}` : ''
    }`,
  )
}

export const onLikeEpisode = (episodeId: string): Promise<ApiFetch> => {
  return ApiFetch.post(`${apiBaseUrl}/content/episodes/${episodeId}/like`)
}

export const onUnlikeEpisode = (episodeId: string): Promise<ApiFetch> => {
  return ApiFetch.del(`${apiBaseUrl}/content/episodes/${episodeId}/like`)
}

export const getSubscription = async (): Promise<ApiFetch> => {
  return ApiFetch.get(`${apiBaseUrl}/billing/subscriptions`)
}

export const getUserSupportersPodcasts = async (): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/billing/subscriptions/history`)

export const getUserRecurrentSupports = async (
  active: boolean,
): Promise<any> => {
  const res = await ApiFetch.get(
    `${apiBaseUrl}/billing/supports/history?active=${active}`,
  )

  const dataset = res.data

  const extractInfoMap = (): any => {
    let datasetMap: any = {}

    for (const datasetItem of dataset) {
      if (datasetMap[datasetItem.podcastName]) {
        datasetMap = {
          ...datasetMap,
          [datasetItem.podcastName]: [
            ...datasetMap[datasetItem.podcastName],
            datasetItem,
          ],
        }
      }

      if (!datasetMap[datasetItem.podcastName]) {
        datasetMap = {
          ...datasetMap,
          [datasetItem.podcastName]: [datasetItem],
        }
      }
    }

    return datasetMap
  }

  const infoMap = extractInfoMap()

  return Object.keys(infoMap).map((key: string) => ({
    info: infoMap[key].map((infoItem: any) => ({
      podcastId: infoItem.podcastId,
      title: key,
      year: infoItem.createdAt.split('/')[2],
      value: `R$ ${infoItem.value}`,
      date: infoItem.createdAt,
    })),
  }))
}

export const getUserTipSupports = async (): Promise<any> => {
  const res = await ApiFetch.get(`${apiBaseUrl}/billing/tips/history`)
  const podcasts = res.data

  const makeMapByYear = (): any => {
    let mapItemsByYear: any = {}

    for (const podcast of podcasts) {
      const year = podcast.createdAt.split('/')[2]
      if (!mapItemsByYear[year]) {
        mapItemsByYear[year] = []
      }

      mapItemsByYear = {
        ...mapItemsByYear,
        [year]: [...mapItemsByYear[year], podcast],
      }
    }

    return mapItemsByYear
  }

  const makeMapByMonth = (): any => {
    const mapByYear = makeMapByYear()

    let mapItemsByMonth: any = {}

    for (let i = 0; i < Object.keys(mapByYear).length; i++) {
      const key = Object.keys(mapByYear)[i]
      mapItemsByMonth = {}

      for (let j = 0; j < mapByYear[key].length; j++) {
        const month = getMonthText(mapByYear[key][j].createdAt)

        if (!mapItemsByMonth[month]) {
          mapItemsByMonth = {
            ...mapItemsByMonth,
            [month]: [mapByYear[key][j]],
          }
        } else {
          mapItemsByMonth = {
            ...mapItemsByMonth,
            [month]: [...mapItemsByMonth[month], mapByYear[key][j]],
          }
        }
      }

      mapByYear[key].dataset = mapItemsByMonth
    }

    return mapByYear
  }

  return [makeMapByMonth()]
}

export const getAnalytics = async (
  params: TYPES.AnalyticsParams,
): Promise<ApiFetch> => {
  return ApiFetch.get(
    `${apiBaseUrl}/analytics?${ApiFetch.toQueryString(params)}`,
  )
}

export const getAnalyticsData = async (
  params: TYPES.AnalyticsParams,
): Promise<TYPES.AnalyticsType[]> => {
  const res = await ApiFetch.get(
    `${apiBaseUrl}/analytics?${ApiFetch.toQueryString(params)}`,
  )
  return res.data.map((item: any) => {
    return {
      ...item,
      value: parseFloat(item.value),
      streamingValue: parseFloat(item.streamingValue),
      subscriptionValue: parseFloat(item.subscriptionValue),
      supportValue: parseFloat(item.supportValue),
      tipValue: parseFloat(item.tipValue),
    }
  })
}

export const getSummarizedAnalytics = async (
  podcastId: string,
): Promise<ApiFetch> => {
  return ApiFetch.get(`${apiBaseUrl}/analytics/summary/${podcastId}`)
}

// PODCAST OWNER
export const listPodcastsAvailableForClaimingOwnership =
  (): Promise<ApiFetch> =>
    ApiFetch.get(`${apiBaseUrl}/content/podcasts/own/maybe`)

export const clainOwnershipForPodcast = (
  podcastId: string,
): Promise<ApiFetch> =>
  ApiFetch.post(`${apiBaseUrl}/content/podcasts/${podcastId}/join`)

export const getPodcastReceivingInfo = (podcastId: string): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/billing/podcasts/${podcastId}/receivingInfo`)

export const upsertPodcastReceivingInfo = (
  podcastId: string,
  podcastReceivingInfo: TYPES.PodcastReceivingInfo,
): Promise<ApiFetch> =>
  ApiFetch.post(
    `${apiBaseUrl}/billing/podcasts/${podcastId}/receivingInfo`,
    podcastReceivingInfo,
  )

// COUPON
export const validateCoupon = (coupon: string): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/billing/coupons/valid/${coupon}`)

// EMAILS
export const deactivateAllEmails = (): Promise<ApiFetch> =>
  ApiFetch.post(`${apiBaseUrl}/notifications/emails/deactivateAll`)

export const deactivatePodcastEmails = (podcastId: string): Promise<ApiFetch> =>
  ApiFetch.post(`${apiBaseUrl}/notifications/emails/deactivate/${podcastId}`)

// REPORT
export const reportContent = (
  body: TYPES.ReportContentBody,
): Promise<ApiFetch> => ApiFetch.post(`${apiBaseUrl}/content/report`, body)

// FORUM
export const getPodcastTopicsList = async (
  podcastId: string,
  lastDateTimestamp: string | number,
): Promise<ApiFetch> => {
  return ApiFetch.get(
    `${apiBaseUrl}/content/topics/podcast/${podcastId}?startDate=${lastDateTimestamp}`,
  )
}

export const getTopic = async (topicId: string): Promise<ApiFetch> => {
  return await ApiFetch.get(`${apiBaseUrl}/content/topic/${topicId}`)
}

export const getTopicComments = async (topicId: string): Promise<ApiFetch> => {
  return ApiFetch.get(`${apiBaseUrl}/content/podcast/topic/${topicId}/comments`)
}

export const createTopicComment = async (
  topicId: string,
  body: string,
): Promise<ApiFetch> =>
  ApiFetch.post(`${apiBaseUrl}/content/podcast/topic/${topicId}/comment`, {
    body,
  })

export const createPixTipCharge = async (
  podcastId: string,
  value: string,
  documentNumber: string,
): Promise<ApiFetch> =>
  ApiFetch.post(`${apiBaseUrl}/billing/tips`, {
    podcastId,
    paymentMethodType: 'pix',
    paymentMethodId: '',
    value,
    document: {
      type: 'cpf',
      number: documentNumber,
    },
  })

export const getPodcastId = async (
  podcastUrlPathName: string,
): Promise<ApiFetch> => {
  return ApiFetch.get(
    `${apiBaseUrl}/content/podcast/getId/${podcastUrlPathName}`,
  )
}

export const confirmSecondaryEmail = async (
  userId: string,
): Promise<ApiFetch> =>
  ApiFetch.post(`${apiBaseUrl}/users/confirmSecondaryEmail/${userId}`)

export const uploadProfileImage = async (
  profileImage: File,
): Promise<ApiFetch> => {
  const state = store.getState()
  const profileType = R.path(
    ['profiles', 'currentProfile', 'profile'],
    state,
  ) as string

  if (profileType !== 'listener') {
    throw new Error('Profile type should be listener')
  }

  return ApiFetch.upload(`${apiBaseUrl}/users/profileImage`, profileImage)
}

export const createPlaylist = async (
  podcastId: string,
  body: {
    name: string
  },
): Promise<ApiFetch> => {
  return ApiFetch.post(
    `${apiBaseUrl}/content/podcast/${podcastId}/playlist`,
    body,
  )
}

export const updatePlaylist = async (
  podcastId: string,
  playlistId: string,
  body: {
    name: string
  },
): Promise<ApiFetch> => {
  return ApiFetch.put(
    `${apiBaseUrl}/content/podcast/${podcastId}/playlist/${playlistId}`,
    body,
  )
}

export const deletePlaylist = async (
  podcastId: string,
  playlistId: string,
): Promise<ApiFetch> => {
  return ApiFetch.del(
    `${apiBaseUrl}/content/podcast/${podcastId}/playlist/${playlistId}`,
  )
}

export const addItemToPlaylist = async (
  podcastId: string,
  playlistId: string,
  body: {
    episodeId: string
  },
): Promise<ApiFetch> => {
  return ApiFetch.post(
    `${apiBaseUrl}/content/podcast/${podcastId}/playlist/${playlistId}/item`,
    body,
  )
}

export const removeItemFromPlaylist = async (
  podcastId: string,
  playlistId: string,
  itemId: string,
): Promise<ApiFetch> => {
  return ApiFetch.del(
    `${apiBaseUrl}/content/podcast/${podcastId}/playlist/${playlistId}/item/${itemId}`,
  )
}

export const reorderItemInPlaylist = async (
  podcastId: string,
  playlistId: string,
  itemId: string,
  body: {
    position: number
  },
): Promise<ApiFetch> => {
  return ApiFetch.put(
    `${apiBaseUrl}/content/podcast/${podcastId}/playlist/${playlistId}/item/${itemId}`,
    body,
  )
}

export const listPlaylistItems = async (
  playlistId: string,
  podcastId: string,
): Promise<ApiFetch> =>
  ApiFetch.get(
    `${apiBaseUrl}/content/podcast/${podcastId}/playlists/${playlistId}/items`,
  )

export const createEpisode = async (
  podcastId: string,
  body: {
    title: string
    description: string
    playlist?: string
    visibility: string
    releaseDate?: string
    file?: string
    duration?: number
  },
): Promise<ApiFetch> => {
  return ApiFetch.post(
    `${apiBaseUrl}/content/podcasts/${podcastId}/episodes`,
    body,
  )
}

export const updateEpisode = async (
  episodeId: string,
  podcastId: string,
  body: {
    title: string
    summary?: string
    description: string
    author?: string
    link?: string
    episodeNumber?: number
    hasExplicitContent?: boolean
    exclusiveToSupporters?: boolean
    releaseDate: string
    duration?: number
    audioUrl?: string
    isArchived?: boolean
    audio?: any
    image?: string
  },
): Promise<ApiFetch> => {
  return ApiFetch.put(
    `${apiBaseUrl}/content/podcast/${podcastId}/episodes/${episodeId}`,
    body,
  )
}

export const getPodcastsCategories = async (): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/content/categories`)

export const getPodcastsContentTypes = async (): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/content/contentTypes`)

// Email types
export const toggleEmailType = async (
  type: 'churn',
  isActive: boolean,
): Promise<ApiFetch> =>
  ApiFetch.put(`${apiBaseUrl}/notifications/userDeactivatedEmailTypes`, {
    emailType: type,
    isActive,
  })

export const listProfileEmailTypes = async (): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/notifications/emailTypes`)

export const getTelegramChatInvite = async (
  telegramChatId: string,
): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/content/telegram-chats/${telegramChatId}/invite`)

export const getPodcastTelegramAccount = async (
  podcastId: string,
): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/content/podcast/${podcastId}/telegram-account`)

export const getPodcastTelegramChats = async (
  podcastId: string,
): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/content/podcast/${podcastId}/telegram-chats`)

export const handleLinkTelegramChat = async (
  podcastId: string,
  telegramChatId: string,
  linked: boolean,
): Promise<ApiFetch> =>
  ApiFetch.patch(
    `${apiBaseUrl}/content/podcast/${podcastId}/telegram-chats/${telegramChatId}/link`,
    { linked },
  )

export const deletePodcastTelegramChat = async (
  podcastId: string,
  telegramChatId: string,
): Promise<ApiFetch> =>
  ApiFetch.del(
    `${apiBaseUrl}/content/podcast/${podcastId}/telegram-chats/${telegramChatId}`,
  )

export const updateTelegramChatAccess = async (
  podcastId: string,
  telegramChatId: string,
  access: 'public' | 'supporters',
): Promise<ApiFetch> =>
  ApiFetch.patch(
    `${apiBaseUrl}/content/podcast/${podcastId}/telegram-chats/${telegramChatId}`,
    { access },
  )

export type TelegramUser = {
  id: number
  first_name: string
  last_name?: string
  username?: string
  photo_url?: string
  auth_date: number
  hash: string
}

export const linkPodcastTelegramAccount = async (
  podcastId: string,
  user: TelegramUser,
): Promise<ApiFetch> => {
  const { hash, ...telegramUser } = user
  return ApiFetch.post(
    `${apiBaseUrl}/content/podcast/${podcastId}/telegram-account`,
    { hash, telegramUser },
  )
}

export const hasUserTelegramAccount = async (): Promise<ApiFetch> => {
  return ApiFetch.get(`${apiBaseUrl}/content/telegram-account`)
}

export const linkUserTelegramAccount = async (
  user: TelegramUser,
): Promise<ApiFetch> => {
  const { hash, ...telegramUser } = user
  return ApiFetch.post(`${apiBaseUrl}/content/telegram-account`, {
    hash,
    telegramUser,
  })
}

export const getPartialRecipient = async (
  podcastId: string,
): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/billing/podcastId/${podcastId}/recipient/filled`)

export const getRecipientStatus = async (
  podcastId: string,
): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/billing/podcastId/${podcastId}/recipient/status`)

interface CreateRecipientInput {
  documentNumber: string
  email: string
  phoneNumber: string
  address: {
    street: string
    number: string
    neighborhood: string
    city: string
    state: string
    zipCode: string
    complementary: string
  }
  bankAccount: {
    holderName: string
    bankCode: string
    branchNumber: string
    accountNumber: string
    type: 'savings' | 'checking'
  }

  companyName?: string
  annualRevenue?: string
  managingPartner?: {
    documentNumber: string
    email: string
    name: string
    birthDate: string
    monthlyIncome: string
    professionalOccupation: string
    phoneNumber: string
    address: {
      street: string
      number: string
      neighborhood: string
      city: string
      state: string
      zipCode: string
      complementary: string
    }
  }

  name?: string
  birthDate?: string
  monthlyIncome?: string
  professionalOccupation?: string
}

export const createRecipient = async (
  podcastId: string,
  body: CreateRecipientInput,
): Promise<ApiFetch> =>
  ApiFetch.post(`${apiBaseUrl}/billing/podcastId/${podcastId}/recipient`, body)

export const generateKycLink = async (podcastId: string): Promise<ApiFetch> =>
  ApiFetch.post(`${apiBaseUrl}/billing/podcastId/${podcastId}/recipient/kyc`)
