import React from 'react'
import { Colors } from 'styles'
import * as Styled from './ButtonShadow.styled'

interface ButtonProps {
  variant?: 'primary' | 'secondary' | 'tertiary'
  size?: 'large' | 'regular' | 'small'
  label?: string
  type?: 'black' | 'colored'
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
  onPress?: () => void
  disabled?: boolean
  style?: any
}

const ButtonShadow: React.FC<ButtonProps> = ({
  variant,
  onPress,
  size = 'regular',
  label,
  type = 'colored',
  leftIcon,
  rightIcon,
  disabled,
  style,
}) => {
  const isColored = type === 'colored'

  let backgroundColor = isColored ? Colors.PURPLE[400] : Colors.GREY[400]
  let shadowColor = Colors.WHITE
  let fontColor = Colors.WHITE
  let hoverColor = isColored ? Colors.PURPLE[50] : Colors.GREY[200]
  let noBorder = false

  if (variant === 'secondary') {
    backgroundColor = Colors.WHITE
    shadowColor = Colors.GREY[400]
    fontColor = isColored ? Colors.PURPLE[400] : Colors.GREY[400]
  }

  if (variant === 'tertiary') {
    backgroundColor = 'transparent'
    shadowColor = 'transparent'
    fontColor = isColored ? Colors.PURPLE[400] : Colors.GREY[400]
    noBorder = true
  }

  return (
    <Styled.ButtonShadowWrapper
      backgroundColor={backgroundColor}
      shadowColor={shadowColor}
      onClick={disabled ? null : onPress}
      size={size}
      hoverColor={hoverColor}
      noBorder={noBorder}
      disabled={disabled}
      style={style}
    >
      {leftIcon && leftIcon}
      {label && (
        <Styled.ButtonShadowLabel
          fontColor={fontColor}
          leftIcon={!!leftIcon}
          size={size}
        >
          {label}
        </Styled.ButtonShadowLabel>
      )}
      {rightIcon && rightIcon}
    </Styled.ButtonShadowWrapper>
  )
}

export default ButtonShadow
