import React from 'react'
import * as Styled from './AccountLinkInstructions.styled'

const AccountLinkInstructions: React.FC = () => {
  return (
    <Styled.Container>
      <Styled.Title>Sua conta já está vinculada em um podcast</Styled.Title>
    </Styled.Container>
  )
}

export default AccountLinkInstructions
