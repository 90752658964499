import styled from 'styled-components'
import { Colors } from 'styles'
import { LETTER_SPACING_1 } from 'styles/typography'

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 8px 0px;
`

export const Title = styled.span`
  font-family: SpaceGrotesk;
  font-weight: 700;
  font-size: 22px;
  letter-spacing: -${LETTER_SPACING_1}px;
`

export const QrCodeContainer = styled.div``

export const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`

export const LinkActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`

export const Instructions = styled.p`
  font-family: SpaceGrotesk;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -${LETTER_SPACING_1}px;
  background-color: ${Colors.SUPPORT_INFORMATION};
  color: ${Colors.WHITE};
  padding: 8px 18px;
  width: fit-content;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 0;
  margin-top: 10px;
`
