import { BodySmall } from 'components/texts'
import React from 'react'
import styled from 'styled-components'
import { Colors } from 'styles'

import { Episode, EpisodeDTO, FullSupportInformation } from 'types'
import parseSecondsToText from 'utils/parseSecondsToText'
import { useTypedSelector } from '../../reducers'
import EpisodeCard, { EpisodeCardProps } from './EpisodeCard'

interface EpisodeCardWrapperProps
  extends Pick<
    EpisodeCardProps,
    'onPlay' | 'onStop' | 'onRewind' | 'onSkip' | 'isPlaying'
  > {
  episode: Episode | EpisodeDTO
  currentTime?: number
  podcastId: string
  episodeId?: string
  idx?: number
  podcastFullSupportInformation: FullSupportInformation
}

const TabListWrapper = styled.div`
  width: calc(100%);
`

const EpisodeCardWrapper: React.FC<EpisodeCardWrapperProps> = ({
  episode,
  isPlaying,
  onPlay,
  onStop,
  onRewind,
  onSkip,
  currentTime,
  podcastId,
  episodeId,
  idx,
  podcastFullSupportInformation,
}) => {
  const currentProfile = useTypedSelector(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const blockedForSupporters = (): boolean => {
    if (!currentProfile) return episode.exclusiveToSupporters
    if (!episode.podcast.userProfileData) return true

    return episode.exclusiveToSupporters
      ? !episode.podcast.userProfileData.isSupporter
      : false
  }

  return (
    <TabListWrapper>
      <EpisodeCard
        title={episode.title}
        description={episode.description}
        image={
          episode.image || episode.podcast.image || episode.podcast.thumbnail
        }
        duration={parseSecondsToText(episode.duration)}
        releaseDate={episode.releaseDate}
        blockedForSupporters={blockedForSupporters()}
        onPlay={onPlay}
        onStop={onStop}
        onSkip={onSkip}
        onRewind={onRewind}
        isPlaying={isPlaying}
        currentTime={currentTime ? parseSecondsToText(currentTime) : undefined}
        userLoggedIn={!!currentProfile}
        podcastId={podcastId}
        episodeId={episodeId}
        idx={idx}
        podcastFullSupportInformation={podcastFullSupportInformation}
      />
    </TabListWrapper>
  )
}

export default EpisodeCardWrapper
