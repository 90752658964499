import styled from 'styled-components'
import { Colors } from 'styles'
import { LETTER_SPACING_1 } from 'styles/typography'

export const CardContainer = styled.div<{ isOdd?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 0px;
`

export const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`

export const Title = styled.span`
  font-family: SpaceGrotesk;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: -${LETTER_SPACING_1}px;
`

export const Name = styled.span`
  font-family: SpaceGrotesk;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -${LETTER_SPACING_1}px;
`

export const Id = styled.span`
  font-family: SpaceGrotesk;
  margin-left: 16px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -${LETTER_SPACING_1}px;
  line-height: calc(18px * 1.5);
  color: ${Colors.LIGHTER_GRAY};
  align-self: baseline;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`
