import React, { Fragment, useState } from 'react'
import * as Styled from './ArticleCard.styled'
import { ArticleSummaryDTO, FullSupportInformation } from 'types'
import { useGoToRoute } from '../../Routes/RouteAux'
import Dialog from '../../components/Dialog/Dialog'

import { getArticlePath, getPaymentsPath } from '../../Routes/RouteNames'

export interface ArticleCardProps
  extends Pick<
    ArticleSummaryDTO,
    | 'id'
    | 'title'
    | 'description'
    | 'image'
    | 'publishedAt'
    | 'visibility'
    | 'userHasAccessToArticle'
    | 'state'
  > {
  podcastId: string
  pageViews?: number
  editable?: boolean
  handleDelete?: () => void
  handleEdit?: () => void
  idx?: number
  podcastFullSupportInformation: FullSupportInformation
}
//! TODO: tornar compativel com edicao
const ArticleCard: React.FC<ArticleCardProps> = ({
  id,
  publishedAt,
  pageViews,
  visibility,
  userHasAccessToArticle,
  image,
  title,
  description,
  editable,
  handleDelete,
  handleEdit,
  podcastId,
  state,
  idx,
  podcastFullSupportInformation,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const goToRoute = useGoToRoute()

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const goToArticlePage = () => {
    if (userHasAccessToArticle) {
      goToRoute(getArticlePath(id))
    } else {
      setIsModalOpen(true)
    }
  }

  return (
    <Fragment>
      <Dialog
        isDialogOpen={isModalOpen}
        handleCloseDialog={handleCloseModal}
        dialogText={
          visibility === 'supporters'
            ? `.txt exclusivo para apoiadores`
            : `falha ao acessar .txt`
        }
        dialogActionButtonText="apoiar podcast"
        onClickDialogActionButton={() =>
          goToRoute(
            getPaymentsPath(
              podcastFullSupportInformation.supportSummary.podcastId,
              podcastFullSupportInformation.supportTiers[0].id,
            ),
          )
        }
        noActionText="ok"
      />

      <Styled.ArticleCard
        isOdd={idx && idx % 2 === 1}
        onClick={() => {
          if (!editable) {
            goToArticlePage()
          }
        }}
        clickable={!editable}
        key={id}
        style={{ opacity: state === 'draft' ? 0.6 : 1 }}
      >
        <Styled.CoverAndIconWrapper>
          <Styled.ArticleCover
            image={image}
            lowOpacity={!userHasAccessToArticle}
          />
          <div style={{ zIndex: 1 }}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 3C8.68629 3 6 5.68629 6 9C6 11.3233 6.86366 13.6467 10.9988 13.9634C11.5495 14.0056 11.9195 14.4473 11.6287 14.9168C10.9217 16.0583 9.3221 17.7744 7.85865 19.225C7.21093 19.867 7.66331 20.9368 8.55906 20.7654C15.1367 19.5067 18 13.439 18 8.81371C18 5.5 15.3137 3 12 3Z"
                fill="#735CE9"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 4.5C9.51472 4.5 7.5 6.51472 7.5 9C7.5 10.0077 7.69294 10.7663 8.11744 11.2939C8.51281 11.7853 9.31617 12.3301 11.1134 12.4678C11.733 12.5153 12.4132 12.7987 12.8485 13.4061C13.3423 14.0951 13.3536 14.9806 12.9039 15.7066C12.3969 16.5253 11.5359 17.5451 10.6087 18.5449C14.6397 16.6667 16.5 12.3482 16.5 8.81371C16.5 6.38972 14.5479 4.5 12 4.5ZM7.85865 19.225C9.3221 17.7744 10.9217 16.0583 11.6287 14.9168C11.9195 14.4473 11.5495 14.0056 10.9988 13.9634C6.86366 13.6467 6 11.3233 6 9C6 5.68629 8.68629 3 12 3C15.3137 3 18 5.5 18 8.81371C18 13.439 15.1367 19.5067 8.55906 20.7654C7.66331 20.9368 7.21093 19.867 7.85865 19.225Z"
                fill="#735CE9"
              />
            </svg>
          </div>
        </Styled.CoverAndIconWrapper>
        <Styled.Column>
          <Styled.InfosTitleAndDescriptionWrapper>
            <Styled.ArticleTitle>
              {title} {!userHasAccessToArticle && <Styled.PadlockWithMargin />}
            </Styled.ArticleTitle>
            <Styled.AdditionalInfo>{publishedAt}</Styled.AdditionalInfo>
          </Styled.InfosTitleAndDescriptionWrapper>
          <Styled.InfosTitleAndDescriptionWrapper>
            {editable && typeof pageViews === 'number' && state !== 'draft' && (
              <Styled.AdditionalInfoRight>
                {pageViews === 1
                  ? '1 visualização'
                  : `${pageViews} visualizações`}
              </Styled.AdditionalInfoRight>
            )}
            {editable && state === 'draft' && (
              <Styled.AdditionalInfoRight variant="alert">
                {'Rascunho'}
              </Styled.AdditionalInfoRight>
            )}
          </Styled.InfosTitleAndDescriptionWrapper>
        </Styled.Column>
      </Styled.ArticleCard>
    </Fragment>
  )
}

export default ArticleCard
