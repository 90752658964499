import React, { Fragment } from 'react'
import * as Styled from './ChatAccountCard.styled'

export interface ChatCardProps {
  avatar?: string
  name: string
  id: number
}

const ChatAccountCard: React.FC<ChatCardProps> = ({ avatar, name, id }) => {
  return (
    <Fragment>
      <Styled.CardContainer>
        {!!avatar && <Styled.Avatar src={avatar} />}

        <Styled.Column>
          <Styled.Title>Conta Vinculada</Styled.Title>
          <Styled.Name>{name}</Styled.Name>
        </Styled.Column>

        <Styled.Column>
          <Styled.Id>id: {id}</Styled.Id>
        </Styled.Column>
      </Styled.CardContainer>
    </Fragment>
  )
}

export default ChatAccountCard
